<script>
export default {
  name: 'Button',

  props: {
    tag: { type: String, default: 'button' },
    size: { type: String, default: 'm' },
    color: { type: String, default: 'accent' },
    disabled: Boolean,
    icon: Boolean,
    outline: Boolean,
    tone: Boolean,
    volumetric: Boolean,
    block: Boolean
  },

  computed: {
    classes () {
      return {
        [`c-button_size-${this.$props.size}`]: true,
        [`c-button_color-${this.$props.color}`]: true,
        'c-button_outline': this.$props.outline,
        'c-button_icon': this.$props.icon,
        'c-button_tone': this.$props.tone,
        'c-button_volumetric': this.$props.volumetric,
        'c-button_block': this.$props.block
      }
    }
  }
}
</script>

<template>
  <component
    class="c-button"
    :class="classes"
    :is="tag"
    :disabled="disabled"
  >
    <div class="c-button__icon c-button__icon_prepend">
      <slot name="prependicon"/>
    </div>

    <div class="c-button__label">
      <slot/>
    </div>

    <div class="c-button__icon c-button__icon_append">
      <slot name="appendicon"/>
    </div>
  </component>
</template>

<style lang="scss" src="./button.scss"></style>
