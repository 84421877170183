import * as amplitude from '@amplitude/analytics-browser'

export class AmpliService {
  instance
  context = 'Ремонты'

  userProps = {
    'Context': this.context,
    'Screen name': 'экран информация'
  }

  constructor () {
    this.instance = amplitude
    this.instance.init(process.env.VUE_APP_AMPLITUDE_APIKEY, {
      defaultTracking: false
    })
  }

  trackElementClick (props) {
    this.instance.track('Element click', {
      ...props,
      ...this.userProps
    })
  }
}
