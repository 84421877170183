<template>
  <Map/>
</template>

<script>
import Map from '@/components/entities/Map'
import {AmpliService} from '@/services/amplitude.service'

export default {
  name: 'App',

  data () {
    return {
      ampli: new AmpliService()
    }
  },

  provide () {
    return {
      ampli: this.ampli
    }
  },

  components: {
    Map
  }
}
</script>
