<script>
import markers from '@/data/markers.json'
import { markerIcons } from '@/data/markerIcons.js'
import MapCard from '@/components/entities/MapCard'

const MARKER_SOURCE = 'marker-source'
const MOCK_POSITION = [37.623082, 55.75254] // Mockba
const ZOOM = 10

export default {
  components: {
    MapCard
  },

  inject: ['ampli'],

  data () {
    return {
      map: null,
      point: null,
      pointElement: null
    }
  },

  computed: {
    isPointExist () {
      return !!this.point
    }
  },

  mounted () {
    setTimeout(this.initilize, 1000)
  },

  methods: {
    async initilize () {
      try {
        await window.ymaps3.ready

        // Initialization
        this.map = new window.ymaps3.YMap(
          this.$refs.container,
          {
            // Temp: Удалить после тестов
            mode: 'raster',
            location: { center: MOCK_POSITION, zoom: ZOOM }
          }
        )

        // Layers
        this.map.addChild(new window.ymaps3.YMapDefaultSchemeLayer({}))
        this.map.addChild(new window.ymaps3.YMapFeatureDataSource({ id: MARKER_SOURCE, zIndex: 1000 }))
        this.map.addChild(new window.ymaps3.YMapLayer({ source: MARKER_SOURCE, type: 'markers', zIndex: 1800 }))

        // Points
        if (markers?.length) {
          markers.forEach((_marker, index) => {
            this.map.addChild(
              this.getMarker(_marker, index)
            )
          })
        }

        // Post message
        window.postMessage('init')
      } catch (error) {
        console.log('Произошла ошибка инициализации карты', error)
      }
    },

    getMarker (marker, index) {
      const eventHandlerLink = this.onMarkerClick
      const id = `marker-${index}`
      const markerElement = document.createElement('div')
      markerElement.classList.add('p-map__marker')
      markerElement.innerHTML = markerIcons[marker.service]
      markerElement.id = id

      return new window.ymaps3.YMapMarker(
        {
          coordinates: [marker.lon, marker.lat],
          id,
          source: MARKER_SOURCE,
          onClick: () => eventHandlerLink(marker, id)
        },
        markerElement
      )
    },

    onMarkerClick (marker, id) {
      this.resetPoint()

      const _point = this.$refs.container.querySelector(`#${id}`)

      if (_point) {
        this.pointElement = _point
        this.pointElement.classList.add('p-map__marker_active')
        this.point = marker

        this.ampli.trackElementClick({
          'Element name': 'точка на карте'
        })
      }
    },

    resetPoint () {
      if (this.pointElement) {
        this.pointElement.classList.remove('p-map__marker_active')
        this.pointElement = null
        this.point = null
      }
    },

    onMapCardInputState (value) {
      if (!value) {
        this.resetPoint()
      }
    }
  }
}
</script>

<template>
  <div class="p-map">
    <div ref="container" class="p-map__container"/>

    <MapCard
      :value="isPointExist"
      class="p-map__card"
      :data="point"
      @input="onMapCardInputState"
    />
  </div>
</template>

<style lang="scss" src="./style.scss"></style>
