<script functional>
export default {}
</script>

<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M8.00016 1.33398C6.68163 1.33398 5.39269 1.72498 4.29636 2.45752C3.20004 3.19006 2.34556 4.23125 1.84097 5.44943C1.33638 6.66758 1.20436 8.00805 1.4616 9.30125C1.71883 10.5945 2.35377 11.7823 3.28612 12.7147C4.21847 13.6471 5.40636 14.282 6.69956 14.5393C7.99276 14.7965 9.33323 14.6645 10.5514 14.1599C11.7696 13.6553 12.8108 12.8008 13.5433 11.7045C14.2758 10.6081 14.6668 9.31918 14.6668 8.00065C14.6668 7.12518 14.4944 6.25826 14.1594 5.44943C13.8244 4.64059 13.3333 3.90566 12.7142 3.2866C12.0952 2.66755 11.3602 2.17648 10.5514 1.84145C9.74256 1.50642 8.87563 1.33398 8.00016 1.33398ZM11.1115 8.86198H7.11116V4.19532H8.4445V7.52865H11.1115V8.86198Z" fill="currentColor"/>
    </g>
  </svg>
</template>
