<script>
import Button from '@/components/ui/Button'
import Icons from '@/components/ui/Icons'

const COPY_TEXT = 'Копировать адрес'
const COPIED_TEXT = 'Скопировано'

export default {
  name: 'MapCard',

  inject: ['ampli'],

  components: {
    Button,
    IconCross: Icons.Cross,
    IconPoint: Icons.Point,
    IconClock: Icons.Clock
  },

  props: {
    value: Boolean,
    data: Object
  },

  model: {
    prop: 'value',
    event: 'input'
  },

  data: () => ({
    copyText: COPY_TEXT
  }),

  methods: {
    onCloseClick () {
      this.$emit('input', false)

      this.ampli.trackElementClick({
        'Element name': 'закрыть описание точки'
      })
    },

    onCopyAddressClick () {
      if (this.$props.data?.address) {
        navigator.clipboard.writeText(this.$props.data.address)

        this.copyText = COPIED_TEXT

        setTimeout(() => {
          this.copyText = COPY_TEXT
        }, 2000)
      }

      this.ampli.trackElementClick({
        'Element name': 'копировать адрес'
      })
    }
  }
}
</script>

<template>
  <Transition name="swipe">
    <div v-show="value" class="c-map-card">
      <div class="c-map-card__body">
        <div class="c-map-card__head">
          <div class="c-map-card__title">
            {{ data?.name }}
          </div>

          <div class="c-map-card__close">
            <Button
              color="neutral"
              size="s"
              icon
              @click.native="onCloseClick"
            >
              <IconCross/>
            </Button>
          </div>
        </div>

        <div class="c-map-card__content">
          <div class="c-map-card__type">
            {{ data?.service }}
          </div>

          <div class="c-map-card__attributes">
            <div class="c-map-card__attributes-item">
              <div class="c-map-card__icon">
                <IconPoint/>
              </div>

              <div class="c-map-card__caption">
                {{ data?.address }}
              </div>
            </div>

            <div v-if="data?.TIME_FROM && data?.TIME_TO" class="c-map-card__attributes-item">
              <div class="c-map-card__icon">
                <IconClock/>
              </div>

              <div
                v-html="data.TIME_FROM + '&ndash;' + data.TIME_TO"
                class="c-map-card__caption"
              />
            </div>
          </div>

          <div v-if="data?.address" class="c-map-card__action">
            <Button
              block
              @click.native="onCopyAddressClick"
            >
              {{ copyText }}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" src="./style.scss"></style>
