<script functional>
export default {}
</script>

<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Ok">
      <mask id="mask0_4155_2139" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect id="Bounding box" width="24" height="24" fill="#D9D9D9"/>
      </mask>

      <g mask="url(#mask0_4155_2139)">
        <g id="close">
          <path d="M12 13.0504L6.92502 18.1254C6.79169 18.2587 6.62102 18.3294 6.41302 18.3374C6.20436 18.3461 6.02502 18.2754 5.87502 18.1254C5.72502 17.9754 5.65002 17.8004 5.65002 17.6004C5.65002 17.4004 5.72502 17.2254 5.87502 17.0754L10.95 12.0004L5.87502 6.92539C5.74169 6.79206 5.67102 6.62106 5.66302 6.41239C5.65436 6.20439 5.72502 6.02539 5.87502 5.87539C6.02502 5.72539 6.20002 5.65039 6.40002 5.65039C6.60002 5.65039 6.77502 5.72539 6.92502 5.87539L12 10.9504L17.075 5.87539C17.2084 5.74206 17.3794 5.67106 17.588 5.66239C17.796 5.65439 17.975 5.72539 18.125 5.87539C18.275 6.02539 18.35 6.20039 18.35 6.40039C18.35 6.60039 18.275 6.77539 18.125 6.92539L13.05 12.0004L18.125 17.0754C18.2584 17.2087 18.329 17.3794 18.337 17.5874C18.3457 17.7961 18.275 17.9754 18.125 18.1254C17.975 18.2754 17.8 18.3504 17.6 18.3504C17.4 18.3504 17.225 18.2754 17.075 18.1254L12 13.0504Z" fill="#6A737C"/>
        </g>
      </g>
    </g>
  </svg>
</template>
